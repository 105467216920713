import type { HTMLProps, ReactNode } from "react";

import * as styles from "./Container.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  /**
   * Adjust the possible max-width of the container
   *
   * @default null
   */
  type?: "small";
  /**
   * Adjusts the maximum possible width if the page utilizes a sidebar
   *
   * @default false
   */
  withSidebar?: boolean;
  /**
   * Tweaks the margins and paddings to work more naturally if
   * used with a ContainerOverrider
   *
   * @default false
   */
  withinOverrider?: boolean;
  /**
   * Content to render inside a container
   *
   * @default undefined
   */
  children?: ReactNode;
}

const Container = ({ withSidebar, withinOverrider, type, children, ...rest }: Props) => {
  const conditionalStyles = [
    styles.containerDiv,
    type && styles.types[type],
    withSidebar && styles.withSidebar,
    withinOverrider && styles.withinOverrider,
  ];

  return (
    <div css={conditionalStyles} {...rest}>
      {children}
    </div>
  );
};

export { Container };
