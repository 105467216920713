import type { ReactNode } from "react";
import { useContext, createContext, useState } from "react";

import type { Flipper } from "@shared/types/flipper";

type Flippers = Flipper[];
type FlipperResults = { name: string; result: boolean }[];

interface SelectedFlipper {
  name: string;
  enabled: boolean;
}

interface FlippersData {
  flippers: Flippers;
  selectedFlipper: SelectedFlipper;
  setFlipper: (flipper: Flipper, active: boolean) => void;
  setFlippers: (flippers: FlipperResults) => void;
  setSelectedFlipper: (value: SelectedFlipper) => void;
}

const FlippersContext = createContext<FlippersData>({
  flippers: [],
  selectedFlipper: null,
  setFlipper: () => null,
  setFlippers: () => null,
  setSelectedFlipper: () => null,
});

interface Props {
  children: ReactNode;
  flippers: Flippers;
}

const FlippersProvider = ({ flippers, children }: Props) => {
  const [activeFlippers, setActiveFlippers] = useState(flippers);
  const [selectedFlipper, setSelectedFlipper] = useState<SelectedFlipper>(null);

  const setFlipper = (flipper: Flipper, active: boolean) => {
    if (active && !activeFlippers.includes(flipper)) {
      setActiveFlippers([...activeFlippers, flipper]);
    } else if (!active && activeFlippers.includes(flipper)) {
      const newFlippers = [...activeFlippers];
      newFlippers.splice(newFlippers.indexOf(flipper), 1);

      setActiveFlippers(newFlippers);
    }
  };

  const setFlippers = (flipperResults: FlipperResults) => {
    const newFlippers = flipperResults
      .filter(flipper => flipper.result)
      .map(({ name }) => name as Flipper);
    setActiveFlippers(newFlippers);
  };

  return (
    <FlippersContext.Provider
      value={{
        flippers: activeFlippers,
        setFlipper,
        setFlippers,
        selectedFlipper,
        setSelectedFlipper,
      }}
    >
      {children}
    </FlippersContext.Provider>
  );
};

const useFlippers = (...args: Flipper[]): boolean[] => {
  const { flippers } = useContext(FlippersContext);

  return args.map(flipper => flippers.includes(flipper));
};

export { FlippersContext, FlippersProvider, useFlippers };
