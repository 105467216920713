import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const containerDiv = css`
  flex-grow: 1;
  margin: 0 ${dimensions.containerMargin};
  position: relative;
  width: auto;

  max-width: ${dimensions.maxContainer};

  @media (max-width: ${dimensions.tabletMax}) {
    margin: 0 ${dimensions.containerMarginMobile};
  }

  @media (min-width: ${dimensions.breakpoints.fullHD.min + 64}px) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const withSidebar = css`
  /* TODO: Replace this value with Aviary Token value */
  max-width: 1196px;
  margin: 0 auto;

  @container sidebarmain (max-width: 1227px) {
    margin: 0;
    min-width: 100%;
    max-width: none;
    padding-left: ${dimensions.containerMarginMobile};
    padding-right: ${dimensions.containerMarginMobile};
  }

  @container sidebarmain (min-width: 1980px) {
    max-width: 1400px;
  }
`;

/*
 * This is done to ensure absolutely positioned elements
 * respect margins, as those are not included in the box model,
 * whereas padding is. Otherwise, this causes absolutely positioned
 * elements to shift by 1rem
 */
export const withinOverrider = css`
  @container sidebarmain (max-width: 1227px) {
    margin-left: ${dimensions.containerMarginMobile};
    margin-right: ${dimensions.containerMarginMobile};
    max-width: calc(100% - ${dimensions.containerMarginMobile} * 2);
    min-width: calc(100% - ${dimensions.containerMarginMobile} * 2);
    padding-left: 0;
    padding-right: 0;
  }
`;

export const types = {
  small: css`
    max-width: 64rem;
  `,
};
